<template>
    <div class="pay-all m-scroll">
        <HeaderBar title="Checkout" />
        <div class="shopping-main">
            <!-- 支付方式 -->
            <div class="pay-box">
                <template v-for="(item, index) in payList">
                    <div class="pay-item" :class="{'pay-active' : payActive == item.type}" :key="index"
                    @click="payActive = item.type" v-if="(!isWechat && item.type != 1) || isWechat || isPC">
                        <img :src="item.img" alt="">
                        <span>{{item.name}}</span>
                        <img src="@/assets/payment/select.png" alt="">
                        <img src="@/assets/payment/active.png" alt="">
                    </div>
                </template>
            </div>

            <!-- 显示详情 -->
            <div class="isViewDetail" :class="{'viewDetail' : isViewDetail}" @click="isViewDetail = !isViewDetail">
                <span>Order Details</span>
                <span>
                    View all
                    <img src="@/assets/payment/down.png" alt="">
                </span>
            </div>

            <!-- 订单详情 -->
            <template v-if="isViewDetail">
                <is-gift></is-gift>                    
                <div class="store-item m-padding" v-for="(item, index) in order" :key="index">
                    <div class="store-title van-hairline--bottom">
                        <img src="@/assets/payment/icon_dianpu@2x.png" alt="">
                        {{item.title}}
                        <span class="m-mooby" style="width:55px" v-show="item.is_presale == 1">Pre-Sale</span>
                        <span>({{item.goods_list.length}} {{item.goods_list.length == 1 ? 'item' : 'items'}})</span>
                        <div class="isSelfpick">
                            <span>{{item.mailling || delivery_method == 1? 'Self-pickup' : 'Malling'}}</span>
                        </div>
                    </div>
                    <div class="shipping" v-if="!continuePay">
                        <div class="shipping-left" v-if="item.mailling">
                            Address：Unit 133-11121 Horseshoe Way Richmond, BC, V7A5G7 Canada
                        </div>
                        <div class="shipping-left" v-else>
                            {{address.name}} {{address.surname}} {{address.phone}} <br/>
                            {{address.addr}},{{address.city_arr.ename}},{{address.province_arr.ename}},{{address.code}} {{address.country_arr.ename}} 
                        </div>
                    </div>
                    <div class="goods-list">
                        <div class="goods-item" v-for="(it, ind) in item.goods_list" :key="ind">
                            <img :src="it.sku_pic" alt="" class="goods-img">
                            <div class="goods-right">
                                <p class="van-multi-ellipsis--l2">{{status == 1 ? it.goods_ename: it.goods_name}}</p>
                                <div class="sku">{{it.sku_title}}</div>
                                <div class="total">
                                    <span>C${{it.price}}</span>
                                    ×{{it.number}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-cell title="Price"   :value="`C$${item.goods_price}`" :border="false" v-if="!continuePay" />
                    <van-cell :title="'PST(' + (item.city_tax.pst*100).toFixed(0) + '%)'" :value="`C$${item.pst}`" v-if="item.pst != '0.00' && !continuePay" :border="false" />
                    <van-cell :title="'GST(' + (item.city_tax.gst*100).toFixed(0) + '%)'" :value="`C$${item.gst}`" v-if="item.gst != '0.00' && !continuePay" :border="false" />
                    <van-cell :title="'QST(' + (item.city_tax.qst*100).toFixed(0) + '%)'" :value="`C$${item.qst}`" v-if="item.qst != '0.00' && !continuePay" :border="false" />
                    <van-cell :title="'HST(' + (item.city_tax.hst*100).toFixed(0) + '%)'" :value="`C$${item.hst}`" v-if="item.hst != '0.00' && !continuePay" :border="false" />
                    <van-cell title="Postage" :value="`C$${item.mailling ? '0.00' : item.freight}`" :border="false" v-if="!continuePay" />
                    <van-cell title="Coupon"  :value="`C$${item.coupon_price ? item.coupon_price : '0.00'}`" :border="false" v-if="!continuePay" />
                    <van-cell title="Credits in Wallet" :value="`C$${orderTotal.wallet_deduction}`" :border="false" v-if="orderTotal.wallet_deduction - 0 > 0 && !continuePay" />
                    <p class="store-total">Total: C${{orderTotal.total_price}}</p>
                </div>
            </template>
        </div>
        <div class="cart-tools noselect van-hairline--top">
            <div class="price">Total: C${{ orderTotal.total_price }}</div>
            <div class="calc-order" @click="calcOrder">PayNow</div>
        </div>

        <div class="pay-wait" v-if="payWait">
            <div class="wait-box">
                <div class="wait-top">Checking Order Status</div>
                <p>{{5 - payTime}} s</p>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import HeaderBar from '@/components/en/m-header.vue'
import IsGift from '@/components/en/is-gift.vue'
import { getContinuePayTitle, alipayWay, alipayWeb, wechatWay, checkOrderPay } from '@/api/zh/order.js'
import { _isPC } from '@/untils/js/common.js'
import { _isWechat } from '@/untils/js/common.js'
export default {
    components: { HeaderBar, IsGift },
    data() {
        return {
            value: '1',
            order: [],
            payList: [
                {name: 'Alipay', img: require('@/assets/payment/alipay.png'), type: 0},
                {name: 'Wechat', img: require('@/assets/payment/wechat.png'), type: 1},
                {name: 'VISA/MasterCard', img: require('@/assets/payment/visa.png'), type: 2}
            ],
            payActive: 0,
            isViewDetail: false,
            allPrice: 0,
            payTitle: '',
            continuePay: false,
            address: {},
            payTimer: null,
            payTime: 0,
            payWait: false,
            isWechat: false,
            orderTotal: {},
            delivery_method: '',
            status: '',
            isPC: false
        }
    },
    methods: {
        calcOrder(e) {
            // 提交订单
            e.target.classList.add('calc-order-scale')
            setTimeout(() => {
                e.target.classList.remove('calc-order-scale')
            }, 150)

            // 判断支付方式
            if(this.payActive == 2) {
                // 信用卡支付
                if(this.$route.query.id) {
                    // 判断是否是小程序支付
                    if(this.$store.state.isMiniProgram) {
                        let lang = localStorage.getItem('lang')
                        let token = localStorage.getItem('token')
                        window.wx.miniProgram.navigateTo({url: `/pages/visa/visa?title=${this.payTitle}&lang=${lang}&token=${token}`})
                        return false
                    }
                    this.$router.replace(`/en/pay/visa/${this.payTitle}`)
                } else {
                    // 判断是否是小程序支付
                    if(this.$store.state.isMiniProgram) {
                        let lang = localStorage.getItem('lang')
                        let token = localStorage.getItem('token')
                        window.wx.miniProgram.navigateTo({url: `/pages/visa/visa?title=${this.$route.query.title}&lang=${lang}&token=${token}`})
                        return false
                    }
                    this.$router.replace(`/en/pay/visa/${this.$route.query.title}`)
                }
            } else {
                this.payNow(this.payActive, this.$route.query.title || this.payTitle)
            }
        },
        getContinuePayTitleHandle() {
            // 获取继续支付订单号
            getContinuePayTitle(this.$route.query.id).then(res => {
                if(res) {
                    this.payTitle = res.pay_title
                }
            })
        },
        payNow(type = 1, orderTitle) {
            var is_iPad = ( navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
            if (is_iPad || _isPC()) {
                    this.$store.commit('load')
                // 去支付宝支付--通过浏览器打开
                localStorage.setItem('isPay', 1)
                localStorage.setItem('_back', this.$route.fullPath)
                setTimeout(() => {
                   window.location.href = `https://m.moobyyoho.com/en/pay/barcodepay/${orderTitle}?pay_methods=${type == 0 ? 1 : 2}`
                    //   this.$router.push({ path:`/en/pay/barcodepay/${orderTitle}`,query:{pay_methods:type == 0 ? 1 : 2} });
                }, 50)
                setTimeout(() =>{
                    this.isCheckTip()
                }, 15000)
            }else{
                 // 判断是否是小程序支付
                if(this.$store.state.isMiniProgram) {
                    let lang = localStorage.getItem('lang')
                    let token = localStorage.getItem('token')
                        // 小程序支付
                        window.wx.miniProgram.navigateTo({url: `/pages/pay/pay?title=${orderTitle}&lang=${lang}&token=${token}`})
                    
                    return false
                }
                // 唤起支付
                this.$store.commit('load')
                if (type == 0) {
                    // 支付方式为支付宝执行
                    if (!this.isWechat) {
                        // let params = {
                        //     pay_title: orderTitle,
                        //     pay_method: 1
                        // }
                        // alipayWay(params).then((res) => {
                        //     if (res) {
                        //         // 保存跳转数据
                        //         // this.isCheckTip()
                        //         localStorage.setItem('isPay', 1)
                        //         setTimeout(() => {
                        //             window.location.href = res.data.h5pay_url
                        //         }, 50)
                                
                        //         setTimeout(() =>{
                        //             this.isCheckTip()
                        //         }, 3000)
                        //     } else {
                        //         this.$store.commit('unload')
                        //         this.$notify({ type: 'warning', message: res.msg })
                        //     }
                        // })
                        let obj = {
                            pay_title: orderTitle,
                            trans_currency: 'CAD'
                        }
                        alipayWeb(obj).then(res => {
                            if(res) {
                                localStorage.setItem('isPay', 1)
                                setTimeout(() => {
                                    window.location.href = res.data.webpay_url
                                }, 50)
                                setTimeout(() =>{
                                    this.isCheckTip()
                                }, 3000)
                            } else {
                                this.$store.commit('unload')
                                this.$notify({ type: 'warning', message: res.msg })
                            }
                        }).finally(() => {
                            this.$store.commit('unload')
                        })
                    } else {
                        this.$store.commit('unload')
                        // 去支付宝支付--通过浏览器打开
                        localStorage.setItem('isPay', 1)
                        setTimeout(() => {
                            window.location.href = 'https://m.moobyyoho.com/pay/alipay.html?lang=' + 
                            (this.$route.fullPath.split('/')[1].substr(0, 2) || 'en') + '&type=0' +'&ordernum=' + orderTitle
                        }, 50)
                        setTimeout(() =>{
                            this.isCheckTip()
                        }, 3000)
                    }
                } else if (type == 1) {
                    // 支付方式为微信执行
                    let params = {
                        pay_title: orderTitle,
                        pay_method: 2
                    }
                    wechatWay(params).then((res) => {
                        if (res) {
                            // this.isCheckTip()
                            localStorage.setItem('isPay', 1)
                            setTimeout(() => {
                                window.location.href = res.data.h5pay_url
                            }, 50)
                            
                            setTimeout(() =>{
                                this.isCheckTip()
                            }, 6000)
                        } else {
                            this.$store.commit('unload')
                            this.$notify({ type: 'warning', message: res.msg })
                        }
                    })
                }
            }
        },
        checkOrderPayHandle() {
            // 判断支付状态
            this.payWait = true
            this.payTimer = setInterval(() => {
                this.payTime++
                if(this.payTime >= 5) {
                    clearInterval(this.payTimer)
                    // 跳转支付失败
                    this.$router.replace({path: '/en/paystatus', query: { t: this.$route.query.title || this.payTitle, status: 2 }})
                }
                checkOrderPay({pay_title: this.$route.query.title || this.payTitle}).then(res => {
                    if(res && res.type == 1) {
                        clearInterval(this.payTimer)
                        this.$router.replace({path: '/en/paystatus', query: { t: this.$route.query.title || this.payTitle, status: 1 }})
                    }
                })
            }, 1000)
        },
        isCheckTip() {
            this.$store.commit('unload')
            this.$dialog.confirm({
                title: 'Order Payment Status',
                message: ' Have you paid your order?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnPopstate: false
            }).then(() => {
                // 判断是否支付成功
                this.checkOrderPayHandle()
                localStorage.removeItem('isPay')
            }).catch(() => {
                // 跳转到支付失败
                localStorage.removeItem('isPay')
                this.$router.replace({path: '/en/paystatus', query: { t: this.$route.query.title || this.payTitle, status: 2 }})
            })
        }
    },
    created() {
        if(this.$store.state.isMiniProgram) {
            this.payActive = 1
            this.payList = this.payList.filter( item =>{
               return item.name != "Alipay"
            })
        }
        var is_iPad = ( navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
        if(is_iPad || _isPC()) {
            this.isPC = true
        } else {
            this.isPC = false
        }
        this.delivery_method = this.$route.query.delivery_method
        this.status = this.$route.query.status
        if (this.$store.state.isMiniProgram == true) {
          this.payActive = 1
          this.payList = this.payList.filter( item =>{
               return item.name != "Alipay"
           })
        }
        this.$store.commit('unload')
        this.isWechat = _isWechat()
        if(localStorage.getItem('bargain')) {
            this.payList = this.payList.filter( item =>{
               return item.name != "Alipay"
            })
            if(is_iPad || _isPC() || this.isWechat) {
                this.payActive = 1
            } else {
                this.payActive = 2
            }
        }
        let isPay = localStorage.getItem('isPay')
        localStorage.removeItem('isPay')
        
        // 是否支付后返回
        if(isPay == 1) {
            this.isCheckTip()
        }
        if(this.$route.query.id) {
            // 继续支付
            this.continuePay = true
            this.getContinuePayTitleHandle()
            this.order[0] = JSON.parse(localStorage.getItem('order'))
            this.order[0].order_goods.forEach(el => {
                el.sku_pic = el.goods_sku_pic_url
                el.price = el.goods_price
                el.number = el.goods_number
            })
            this.order[0].goods_list = this.order[0].order_goods
            this.order[0].title = this.order[0].shops_title
            this.order[0].total_price = this.order[0].order_total_amount
            this.allPrice = this.order[0].total_price
            this.orderTotal = {
                total_price: this.order[0].order_total_amount,
                wallet_deduction: this.order[0].order_goods[0].goods_wallet_fee
            }
        } else {
            this.order = JSON.parse(localStorage.getItem('order'))
            this.orderTotal = JSON.parse(localStorage.getItem("order_total"))
            this.address = JSON.parse(localStorage.getItem('order_address'))
            this.allPrice = 0
            this.order.forEach(el => {
                this.allPrice += el.total_price - 0
            })
        }

        if(!this.order) {
            // 订单信息不存在
            this.$router.back()
        }
    },
    activated() {
        if (this.$store.state.isMiniProgram == true) {
                this.payActive = 1
            this.payList = this.payList.filter( item =>{
                return item.name != "Alipay"
            })
        }
    }
}
</script>

<style scoped lang="less">
@import './index.less';
</style>

<style lang="less">
.pay-all{
    .step-total{width:80px;height:20px;margin-right:-10px;
        button,input{height:100%;border-radius: 0}
        input{border:1px solid rgba(230,230,230,1);background-color: #fff;margin:0}
        button{width:20px;background-color: rgba(230,230,230,1)}
    }
    .m-cat-padding{padding:0}
    .m-cat-devider{
        margin:15px 0;
        font-size:18px;
        font-weight:500;
        color:rgba(15,30,51,1);
    }
    .pay-wait{position: fixed;top:0;left:0;width:100%;height:100%;background-color: #11111160;z-index: 999;
        .wait-box{position: absolute;top:50%;left:50%;width:280px;height:130px;background-color: #fff;border-radius: 16px;transform: translate(-50%, -50%);
            .wait-top{width:100%;height:38px;line-height: 38px;text-align: center;font-size: 16px;color:#444;border-bottom:1px solid #f1f1f1}
            p{font-size: 24px;color:rgb(255, 79, 79);text-align: center;padding-top:22px;font-weight: 600}
        }
    }
}
</style>

