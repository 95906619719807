<template>
  <transition name="fade">
    <div class="gift-richmond" v-if="giftJson">
      <img class="dp" src="@/assets/lottery/dp-min.png" alt="" />
      <div class="flex">
        <div class="gift-goods">
          <img :src="giftJson.pic" alt="" />
        </div>
        <div class="gift-info">
          <p class="van-multi-ellipsis--l2">{{ giftJson.title_en }}</p>
          <p>Valid period: {{giftJson.start_date}} - {{giftJson.end_date}}</p>
        </div>
        <div class="gift-rule"><span>x</span><span>1</span></div>
      </div>
    </div>
  </transition>
</template>

<script>
import { is_gift } from "@/api/zh/index.js";
export default {
  data() {
    return {
      giftJson: "",
    };
  },
  methods: {
    Gift() {
      is_gift().then((res) => {
        if (res) {
          this.giftJson = res.data;
        }
      });
    },
  },
  created() {
    this.Gift();
  },
};
</script>

<style lang="less" scoped>
.gift-richmond {
  width: 95%;
  margin: auto;
  background-image: url("../../assets/lottery/Group970.png");
  height: 116px;
  background-size: 100% 100%;
  position: relative;
  .dp {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    animation: shan 0.5s infinite;
  }
  @keyframes shan {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }
  .flex {
    width: 100%;
    height: 100%;
    padding: 18px 20px 18px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .gift-goods {
      width: 110px;
      height: 80px;
      background-image: url("../../assets/lottery/Group972.png");
      background-size: 100% 100%;
      img {
        display: block;
        height: 80%;
        margin: auto;
      }
    }
    .gift-info {
      width: 175px;
      p {
        &:nth-child(1) {
          font-size: 15px;
          line-height: 25px;
          color: #000;
        }
        &:nth-child(2),
        &:nth-child(3) {
          font-size: 12px;
          color: #666666;
          line-height: 20px;
        }
      }
    }
    .gift-rule {
      width: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      span {
        display: block;
        &:nth-child(1) {
          font-size: 12px;
        }
        &:nth-child(1) {
          font-size: 16px;
        }
      }
    }
  }
}
</style>